<template>
  <div class="body">
    <div class="container" v-if="!firstModeSwitch">
      <h3>Enter all fields to deploy a new site</h3>
      <div class="form">
        <input
          type="text"
          placeholder="Admin first name"
          class="input"
          v-model.trim="first" />
        <br />
        <input
          type="text"
          placeholder="Admin last name"
          class="input"
          v-model.trim="last" />
        <br />
        <input
          type="text"
          placeholder="Admin email"
          class="input"
          v-model.trim="email" />
        <br />
        <input
          type="text"
          placeholder="Primary Color (must be a valid hexadecimal color code i.e 0000FF)"
          class="input"
          v-model.trim="mainColor" />
        <br />
        <input
          type="text"
          placeholder="Secondary Color (must be a valid hexadecimal color code i.e 0000FF)"
          class="input"
          v-model.trim="accentColor" />
        <br />
        <input
          type="text"
          placeholder="Apply Link (i.e formatted as http://www.google.com)"
          class="input"
          v-model.trim="applyLink" />
        <br />
        <input
          type="text"
          placeholder="Visit Link (i.e formatted as http://www.google.com)"
          class="input"
          v-model.trim="visitLink" />
        <br />
        <input
          type="text"
          placeholder="Back Link (i.e formatted as http://www.google.com)"
          class="input"
          v-model.trim="backLink" />
        <br />
        <br />
        <button class="ls" v-on:click="handleFirstSwitch">next</button>
      </div>
    </div>
    <div class="container" v-if="firstModeSwitch">
      <div class="back" v-on:click="handleFirstSwitch">
        <label>Back</label>
      </div>
      <h2>Final Steps</h2>
      <br />

      <template v-for="(site, index) in siteArray">
        <div :key="`site-index-${index}`">
          <h3>Site {{ index + 1 }} Details</h3>
          <div class="form">
            <input
              type="text"
              :placeholder="`Site name ${index + 1} (must be unique name)`"
              class="input"
              v-model.trim="site.name" />
            <br />
            <div class="flex">
              https://
              <input
                type="text"
                @keydown="validateSiteInput"
                @paste="validatePasteSiteInput($event, site)"
                :placeholder="`Landing page URL ${index + 1}`"
                class="input"
                v-model.trim="site.url" />
              .peerpal.app
            </div>
            <br />
            <select
              required
              name="category"
              id="category"
              v-model.trim="site.orgType">
              <option value="Parent">Parent</option>
              <option value="Student">Student</option>
              <option value="Alumni">Alumni</option>
              <option value="Staff">Faculty/Staff</option>
              <option value="Community">Community</option>
            </select>
            <br />
            <br />
            <button class="btn btn-primary" @click="removeSite(index)">
              Delete
            </button>
            <br />
            <br />
            <br />
          </div>
        </div>
      </template>

      <button class="btn btn-primary" @click="addSite">Add</button>

      <br />
      <br />
      <h3>Your Details</h3>
      <input
        type="text"
        placeholder="PS First"
        class="input"
        v-model.trim="pcFirst" />
      <br />
      <input
        type="text"
        placeholder="PS Last"
        class="input"
        v-model.trim="pcLast" />
      <br />
      <input
        type="text"
        placeholder="PS Email"
        class="input"
        v-model.trim="pcEmail" />
      <br />
      <input
        type="password"
        placeholder="PS Credentials"
        class="input"
        v-model.trim="pcPWD" />
      <br />
      <br />
      <h4>Final Step: Upload School Logo</h4>
      <input
        type="file"
        v-on:change="fileSelected($event)"
        accept="image/*"
        name="file"
        id="fileinput" />
      <br />
      <button class="ls" v-on:click="deployOrgs">Submit</button>
    </div>
  </div>
</template>

<script>
  /* eslint-disable no-useless-escape */
  import axios from "axios";

  export default {
    data() {
      return {
        first: "",
        last: "",
        email: "",
        logo: null,
        org: "PeerPal from Gravyty",
        firstModeSwitch: false,
        mainColor: "",
        accentColor: "",
        applyLink: "",
        visitLink: "",
        backLink: "",
        siteArray: [
          {
            name: null,
            url: null,
            orgType: null,
          },
        ],
        pcFirst: "",
        pcEmail: "",
        pcLast: "",
        pcPWD: "",
      };
    },

    methods: {
      addSite() {
        this.siteArray.push({ name: null, url: null, orgType: null });
      },

      removeSite(index) {
        this.siteArray.splice(index, 1);
      },

      trimHostUrl(url) {
        const inputsToReplace = [".peerpal.app", "peerpalapp"];
        if (
          inputsToReplace.some((badBoiInput) => url.indexOf(badBoiInput) >= 0)
        ) {
          for (const badBoiInput of inputsToReplace) {
            url = url.replace(badBoiInput, "");
          }
        }

        return url;
      },

      validateUrl(key, val) {
        // Copy paste allows .
        val = val.replace(".peerpal.app", "");
        // In case someone types without checking, . isn't allowed
        this[key] = val.replace("peerpalapp", "");
      },

      isSiteInputInvalid(url) {
        return /[^\w-]/i.test(url);
      },

      validateSiteInput(event) {
        const isInvalid = this.isSiteInputInvalid(event.key);

        if (isInvalid) {
          event.preventDefault();
        }
      },

      validatePasteSiteInput(event, site) {
        event.preventDefault();

        let input = (event.clipboardData || window.clipboardData)
          .getData("text")
          .toLowerCase();

        input = this.trimHostUrl(input);

        if (this.isSiteInputInvalid(input)) {
          alert(
            "Invalid input, please ensure url only contains lowercase or uppercase letters, numbers, dashes, or underscores"
          );
          return false;
        }

        site.url = input;
      },

      isEmailValid(email) {
        const reg =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
        return email == "" ? false : reg.test(email) ? true : false;
      },
      handleFirstSwitch() {
        if (
          !this.first ||
          !this.last ||
          !this.email ||
          !this.mainColor ||
          !this.accentColor ||
          !this.backLink ||
          !this.visitLink ||
          !this.applyLink
        ) {
          alert("Please complete all required fields");
          return;
        }

        if (!this.isEmailValid(this.email)) {
          alert("Please enter a valid admin email");
          return;
        }

        const linkRegex = new RegExp(
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/){1}[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        );

        const applyLinkCheck = !!this.applyLink.match(linkRegex);
        const visitLinkCheck = !!this.visitLink.match(linkRegex);
        const backLinkCheck = !!this.backLink.match(linkRegex);

        if (applyLinkCheck === false) {
          alert(
            "Please enter a valid apply link url (ensure the url starts with http://)"
          );
          return;
        }

        if (visitLinkCheck === false) {
          alert(
            "Please enter a valid Visit link url (ensure the url starts with http://)"
          );
          return;
        }

        if (backLinkCheck === false) {
          alert(
            "Please enter a valid back link url (ensure the url starts with http://)"
          );
          return;
        }

        // if colors are missing the #
        if (!this.mainColor.includes("#")) {
          this.mainColor = `#${this.mainColor}`;
        }

        if (!this.accentColor.includes("#")) {
          this.accentColor = `#${this.accentColor}`;
        }

        const regex = new RegExp(/(^[#])([a-zA-Z0-9]{6})$/);
        const colorCheck1 = !!this.mainColor.match(regex);
        const colorCheck2 = !!this.accentColor.match(regex);

        if (colorCheck1 === false) {
          alert("Please enter a valid 6-digit HEX code for the primary color");
          return;
        }

        if (colorCheck2 === false) {
          alert(
            "Please enter a valid 6-digit HEX code for the secondary color"
          );
          return;
        }

        this.firstModeSwitch = !this.firstModeSwitch;
      },
      fileSelected(event) {
        let image = event.target.files[0];
        let fileSize = event.target.files[0].size;
        let reader = new FileReader();
        //checks if file is greated thatn 100KB, and if so saves the smaller version of the file
        if (fileSize > 100000) {
          reader.readAsArrayBuffer(image);
          reader.onload = (event) => {
            let blob = new Blob([event.target.result]);
            window.URL = window.URL || window.webkitURL;
            let blobURL = window.URL.createObjectURL(blob);
            let image = new Image();
            image.src = blobURL;
            image.onload = () => {
              let canvas = document.createElement("canvas");

              if (image.width > image.height) {
                let ratio = 200 / image.width;
                canvas.width = 200;
                canvas.height = ratio * image.height;
              } else {
                let ratio = 200 / image.height;
                canvas.width = ratio * image.width;
                canvas.height = 200;
              }
              let ctx = canvas.getContext("2d");
              ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
              this.logo = canvas.toDataURL("image/png", 1); // get the data from canvas as 70% JPG
              console.log(this.logo);
            };
          };
        } else {
          reader.readAsDataURL(image);
          reader.onload = (event) => {
            this.logo = event.target.result;
            console.log(this.logo);
          };
        }
      },

      hasNull(target) {
        for (var member in target) {
          if (target[member] == null) return true;
        }
        return false;
      },

      validateDuplicates() {
        // Check if there is any non-unique names or links before proceeding
        const sites = this.siteArray.filter((site) => {
          return site.name?.length && site.url?.length;
        });

        const nameList = sites.map((site) => {
          return site.name;
        });

        if (nameList.length !== new Set(nameList).size) {
          return 1;
        }

        const urlList = sites.map((site) => {
          return site.url;
        });

        if (urlList.length !== new Set(urlList).size) {
          return 2;
        }
      },

      deployOrgs: async function () {
        if (this.validateDuplicates() === 1) {
          alert("Each site name must be unique");
          return;
        }

        if (this.validateDuplicates() === 2) {
          alert("Each site url must be unique");
          return;
        }

        if (
          !this.logo ||
          !this.pcFirst ||
          !this.pcLast ||
          !this.pcEmail ||
          !this.pcPWD
        ) {
          alert("Please complete all required fields");
          return;
        }

        for (const [index, site] of this.siteArray.entries()) {
          if (site.name || site.url || site.orgType) {
            if (!site.name) {
              alert(`Please enter a site name for site ${index + 1}`);
              return;
            }

            if (!site.orgType) {
              alert(`Please select an organization type for site ${index + 1}`);
              return;
            }

            if (!site.url) {
              alert(`Please enter a value for landing page url ${index + 1}`);
              return;
            }
          }
        }

        if (!this.isEmailValid(this.pcEmail)) {
          alert("Please enter a valid PS email format to proceed");
          return;
        }

        let result = confirm("Are you sure you wish to start this deployment?");

        if (!result) {
          return;
        }

        const orgDataArr = this.siteArray
          .filter((site) => !this.hasNull(site))
          .map((site) => {
            return {
              name: site.name,
              orgType: site.orgType,
              url: this.toFinalUrl(site.url),
            };
          });

        const organizations = orgDataArr.map((org) => org.name);

        let resp1 = await axios.post(
          `${process.env.VUE_APP_ADMIN_API_URL}dashboard/checkOrgNameValid`,
          {
            organizations,
          }
        );

        if (resp1.data.invalidNames && resp1.data.invalidNames.length > 0) {
          alert(
            `Please choose another site name. The following are already in use and therefore unavailable:${resp1.data.invalidNames}`
          );
          return;
        }

        let resp2 = await axios.post(
          `${process.env.VUE_APP_ADMIN_API_URL}dashboard/checkValidUrl`,
          {
            urls: orgDataArr.map((org) => org.url),
          }
        );

        console.log(resp2.data);
        console.log(resp2.data.invalidUrls);
        console.log(typeof resp2.data.invalidUrls);

        if (resp2.data.invalidUrls && resp2.data.invalidUrls.length > 0) {
          alert(
            `Please choose a different landing page URL. The following is already in use and therefore unavailable: ${resp2.data.invalidUrls}`
          );
          return;
        }

        let body = {
          first: this.first,
          last: this.last,
          email: this.email,
          organizations,
          mainColor: this.mainColor,
          accentColor: this.accentColor,
          logo: this.logo,
          applyLink: this.applyLink,
          visitLink: this.visitLink,
          backLink: this.backLink,
          orgDataArr,
          pcFirst: this.pcFirst,
          pcLast: this.pcLast,
          pcEmail: this.pcEmail,
          pcPWD: this.pcPWD,
        };

        await axios
          .post(`${process.env.VUE_APP_ADMIN_API_URL}dashboard/makeBulk`, body)
          .then((response) => {
            console.log(response);
            alert("Success! You will receive an email confirmation shortly.");
            this.first = "";
            this.last = "";
            this.email = "";
            this.logo = null;
            this.mainColor = "";
            this.accentColor = "";
            this.applyLink = "";
            this.visitLink = "";
            this.backLink = "";

            for (let site of this.siteArray) {
              site.url = null;
              site.name = null;
              site.orgType = null;
            }

            this.pcFirst = "";
            this.pcEmail = "";
            this.pcLast = "";
            this.pcPWD = "";

            this.firstModeSwitch = !this.firstModeSwitch;
          })
          .catch((err) => {
            if (err.response.status === 401) {
              alert("Wrong PS Credentials have been entered please try again");
            } else if (err.response.status === 433) {
              alert(
                "A Site name with any of those names entered already exists, please use a different name"
              );
            } else if (err.response.status === 435) {
              alert(
                "A Site with any of urls entered already exists, please use a different url"
              );
            } else {
              alert("Site Deployment failed");
            }

            console.log(err);
          });
      },
      toFinalUrl(url) {
        if (url) {
          url = this.trimHostUrl(url);
          return `https://${url.toLowerCase()}.peerpal.app`;
        }
        return null;
      },
    },
  };
</script>

<style scoped>
  header {
    width: 100%;
    background-color: var(--school-color);
    height: 115px;
    color: white;
    line-height: 115px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  }
  header img {
    float: left;
    margin: 0 20px;
    cursor: pointer;
    height: 75px;
    max-width: 250px;
    object-fit: contain;
    padding: 20px 0 20px 0;
  }
  .container {
    max-width: 500px;
    min-width: 300px;
    background-color: white;
    margin: 50px auto 5px auto;
    padding: 25px 50px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    margin-top: 5em;
  }
  .container h2 {
    font-size: 2em;
    margin: 20px 0 0 0;
  }
  .container h3 {
    margin: 0 0 20px 0;
    font-weight: 500;
  }
  .container .link {
    color: #007aff;
    border: 0;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
  }
  .link:focus {
    outline: none;
  }
  .link:active {
    opacity: 0.5;
  }
  .form {
    box-sizing: border-box;
    clear: both;
    width: 100%;
  }
  .input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 5px;
    font-size: 15px;
    outline: none;
    transition: all 0.5s ease;
    background-color: #eeeeee;
  }
  .input:focus {
    border: 2px solid grey;
  }
  .ls {
    width: 40%;
    background: blue;
    height: 50px;
    border: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 450;
    color: white;
    border-radius: 25px;
    margin-top: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  .ls:hover {
    transform: scale(1.06);
  }
  .ls:focus {
    outline: none;
  }
  .ls:active {
    opacity: 0.5;
  }
  a {
    color: blue;
  }
  h4 {
    margin: 0;
    font-weight: 550;
  }
  .switchText {
    margin: 15px 0 0 0;
    font-size: 15px;
  }
  .detailText {
    font-size: 15px;
    margin: 40px 0 0 0;
  }
  .back {
    display: flex;
    justify-content: left;
    cursor: pointer;
    height: 20px;
    font-weight: 500;
  }
  .back:active {
    opacity: 0.5;
  }
  .back img {
    object-fit: scale-down;
  }
  .back label {
    cursor: pointer;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  p {
    margin-bottom: 20px;
  }
  #powered-cont {
    max-width: 600px;
    min-width: 400px;
    height: 35px;
    margin: 0 auto 10px auto;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  @media (max-width: 460px) {
    header {
      height: 115px;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 80px auto;
    }
    header img {
      width: 100%;
      padding: 0;
      margin: 5px auto;
      height: 70px;
    }
    header #headerLink {
      margin: 0;
      height: 25px;
      line-height: 25px;
      float: none;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .container {
      margin-left: 10px;
      margin-right: 10px;
      padding: 25px 25px;
    }
  }
</style>
