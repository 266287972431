<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/peerpal-graduway-logo.svg" />
    <Delete />
  </div>
</template>

<script>
  import Delete from "./components/Delete.vue";

  export default {
    name: "App",
    components: {
      Delete,
    },
  };
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
  img {
    height: 200px;
    width: 200px;
  }
</style>
